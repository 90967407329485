import configuration from "../assets/menuImages/Configuración.png";
import buys from "../assets/menuImages/shopping-basket.png";
import security from "../assets/menuImages/apoyo-tecnico.png";
import instalation from "../assets/menuImages/Instalaciones.png";
import incidents from "../assets/menuImages/Incidencias.png";
import shopCar from "../assets/menuImages/Carritos.png";
import satisfactions from "../assets/menuImages/Satisfacciones.png";
import users from "../assets/menuImages/Usuario.png";
import equipment from "../assets/menuImages/Equipos.png";
import projects from "../assets/menuImages/Proyectos.png";
import ganttDiagram from "../assets/menuImages/diagrama-de-flujo.png";
import singUp from "../assets/menuImages/datafono.png";
import business from "../assets/menuImages/business-man.png";
import invoice from "../assets/menuImages/invoice.png";
import deliveryNote from "../assets/menuImages/albaran.png";
import calendario from "../assets/menuImages/calendario.png";
import beneficios from "../assets/menuImages/beneficios.png";
import download from "../assets/menuImages/download.png";
import camionReparto from "../assets/menuImages/camion-de-reparto.png";
import buzon from "../assets/menuImages/buzon.png";
import gastos from "../assets/menuImages/gastos.png";
import tags from "../assets/menuImages/tags.png";
import reasons from "../assets/menuImages/lightbulb.png";
import permiso from "../assets/menuImages/permiso.png";
import conexion from "../assets/menuImages/conexion-a-la-nube.png";
import vacaciones from "../assets/menuImages/vacaciones-de-verano.png";
import equipo from "../assets/menuImages/equipo.png";
import clock from "../assets/menuImages/clock.png";
import productos from "../assets/menuImages/productos.png";
import map from "../assets/menuImages/mapa.png";
import firmar from "../assets/menuImages/firmar.png";

export const PRINCIPALMENU = [
	{
		keyApp: "home",
		navigation: "home",
		title: "Inicio",
		subtitle: "Inicio",
		image: `url(${configuration})`,
		hasChildren: false,
		showInMenu: true,
	},
	{
		keyApp: "instalationsMenu",
		navigation: "instalations",
		title: "Instalación",
		subtitle: "Instalaciones y equipos",
		image: `url(${instalation})`,
		hasChildren: true,
		showInMenu: false,
	},
	{
		keyApp: "incidencesMenu",
		navigation: "incidences",
		title: "Incidencias",
		subtitle: "Tus incidencias y partes de mantenimiento",
		image: `url(${incidents})`,
		hasChildren: true,
		showInMenu: false,
	},
	{
		keyApp: "shopCarts",
		navigation: "shopCarts",
		title: "Compras",
		subtitle: "Carritos y Comprar",
		image: `url(${buys})`,
		hasChildren: true,
		showInMenu: false,
	},
	{
		keyApp: "satisfactions",
		navigation: "satisfactions",
		title: "Satisfacciones",
		subtitle: "Satisfacciones sobre las incidencias",
		image: `url(${satisfactions})`,
		showInMenu: false,
	},
	{
		keyApp: "maintenance",
		navigation: "maintenances",
		title: "Mantenimientos",
		subtitle: "Ver todos los Mantenimientos",
		image: `url(${business})`,
		hasChildren: false,
		showInMenu: true,
	},
	{
		keyApp: "workOrders",
		navigation: "workOrders",
		title: "Partes de mantenimiento",
		subtitle: "Tus partes de mantenimiento",
		image: `url(${firmar})`,
		showInMenu: true,
	},
	{
		keyApp: "bottomfertilizers",
		navigation: "bottomfertilizers",
		title: "Abono de fondo",
		subtitle: "Tu abono de fondo",
		image: `url(${reasons})`,
		showInMenu: true,
	},
	{
		keyApp: "phytosanitarys",
		navigation: "phytosanitarys",
		title: "Fitosanitarios",
		subtitle: "Tu fitosanitario",
		image: `url(${reasons})`,
		showInMenu: true,
	},
	{
		keyApp: "fertigations",
		navigation: "fertigations",
		title: "Fertirrigación",
		subtitle: "Tu fertirrigación",
		image: `url(${reasons})`,
		showInMenu: true,
	},
	{
		keyApp: "projectsMenu",
		navigation: "projects",
		title: "Proyectos",
		subtitle: "Proyectos y diagrama de gantt",
		image: `url(${projects})`,
		hasChildren: true,
		showInMenu: false,
	},
	{
		keyApp: "singUp",
		navigation: "singUp",
		title: "Fichar",
		subtitle: "Fichar entradas y salidas",
		image: `url(${singUp})`,
		showInMenu: true,
	},
	{
		keyApp: "workMenu",
		navigation: "works",
		title: "Trabajos",
		subtitle: "Trabajo",
		image: `url(${configuration})`,
		hasChildren: true,
		showInMenu: true,
	},
	{
		keyApp: "historics",
		navigation: "historics",
		title: "Históricos",
		subtitle: "Históricos",
		image: `url(${projects})`,
		hasChildren: true,
		showInMenu: true,
	},
	{
		keyApp: "master",
		navigation: "master",
		title: "Maestros",
		subtitle: "Maestros",
		image: `url(${configuration})`,
		hasChildren: true,
		showInMenu: true,
	},
	{
		keyApp: "singUpsMenu",
		navigation: "singUps",
		title: "Control de accesos",
		subtitle: "Ver todos los accesos",
		image: `url(${business})`,
		hasChildren: true,
		showInMenu: true,
	},
	{
		keyApp: "RRHH",
		navigation: "RRHH",
		title: "RRHH",
		subtitle: "Recursos humanos",
		image: `url(${equipo})`,
		hasChildren: true,
		showInMenu: true,
	},
	{
		keyApp: "dosification",
		navigation: "dosification",
		title: "Dosificación",
		subtitle: "Productos y configuración",
		image: `url(${productos})`,
		showInMenu: false,
		hasChildren: false,
	},
	{
		keyApp: "invoicesDeliveryNotes",
		navigation: "allInvoicesDeliveryNotes",
		title: "Facturas y albaranes",
		subtitle: "Descargar las facturas y albaranes de este año",
		image: `url(${invoice})`,
		hasChildren: true,
		showInMenu: false,
	},
	{
		keyApp: "repartiments",
		navigation: "repartiments",
		title: "Repartos",
		subtitle: "Ver todos los albaranes de hoy",
		image: `url(${camionReparto})`,
		showInMenu: false,
	},
	{
		keyApp: "expenses",
		navigation: "expenses",
		title: "Gastos",
		subtitle: "Introduce gastos",
		image: `url(${gastos})`,
		showInMenu: false,
	},
	{
		keyApp: "opcua",
		navigation: "opcua",
		title: "OPCUA",
		subtitle: "Introduce la configuración del OPCUA",
		image: `url(${conexion})`,
		showInMenu: false,
	},
	{
		keyApp: "configuration",
		navigation: "configuration",
		title: "Configuración",
		subtitle: "Usuarios y roles",
		image: `url(${configuration})`,
		hasChildren: true,
		showInMenu: true,
	},
	{
		keyApp: "suggestion",
		navigation: "suggestion",
		title: "Sugerencias",
		subtitle: "Introduce errores o sugerencias",
		image: `url(${buzon})`,
		showInMenu: true,
	},
	{
		keyApp: "stadistics",
		navigation: "stadistics",
		title: "Estadísticas",
		subtitle: "Estadísticas sobre la aplicación",
		image: `url(${beneficios})`,
		showInMenu: true,
	},
	{
		keyApp: "test",
		navigation: "test",
		title: "Test",
		subtitle: "Test",
		image: `url(${configuration})`,
		hasChildren: false,
		showInMenu: false,
	},
];

export const CONFIGURATIONMENU = [
	{
		keyApp: "users",
		navigation: "configuration/users",
		title: "Usuarios",
		subtitle: "Todos los usuarios",
		image: `url(${users})`,
		showInMenu: true,
		parent: "configuration",
	},
	{
		keyApp: "configurations",
		navigation: "configuration/configurations",
		title: "Configuración de equipos",
		subtitle: "Campos visibles según el usuario",
		showInMenu: false,
	},
	{
		keyApp: "downloadDocument",
		navigation: "configuration/downloadDocument",
		title: "Descargas de doc.",
		subtitle: "Ver todas las descargas de documentos",
		image: `url(${download})`,
		showInMenu: false,
		parent: "configuration",
	},
	{
		keyApp: "tags",
		navigation: "configuration/tags",
		title: "Tags",
		subtitle: "Ver todas las tags de gastos",
		image: `url(${tags})`,
		showInMenu: false,
		parent: "configuration",
	},
	{
		keyApp: "reasons",
		navigation: "configuration/reasons",
		title: "Motivos",
		subtitle: "Ver todos los motivos de horas extras",
		image: `url(${reasons})`,
		showInMenu: false,
		parent: "configuration",
	},

	{
		keyApp: "roles",
		navigation: "configuration/roles",
		title: "Roles",
		subtitle: "Ver todos los roles",
		image: `url(${permiso})`,
		showInMenu: true,
		parent: "configuration",
	},
];

export const PROJECTSMENU = [
	{
		keyApp: "projects",
		navigation: "projects/projects",
		title: "Proyectos",
		subtitle: "Todos los proyectos",
		image: `url(${projects})`,
		showInMenu: false,
		parent: "projects",
	},
	{
		keyApp: "ganttDiagram",
		navigation: "projects/ganttDiagram",
		title: "Diagrama de gantt",
		subtitle: "Vista del diagrama de gantt",
		image: `url(${ganttDiagram})`,
		showInMenu: false,
		parent: "projects",
	},
];

export const SHOPMENU = [
	{
		keyApp: "shopCarts",
		navigation: "shopCarts/carts",
		title: "Carritos",
		subtitle: "Todos los carritos",
		image: `url(${shopCar})`,
		showInMenu: false,
	},
	{
		keyApp: "buy",
		navigation: "shopCarts/buy",
		title: "Comprar",
		subtitle: "Realiza compras",
		image: `url(${buys})`,
		showInMenu: false,
	},
	{
		keyApp: "securitySheets",
		navigation: "shopCarts/security",
		title: "Fichas técnicas",
		subtitle: "Ver todas las fichas técnicas descargadas",
		image: `url(${security})`,
		showInMenu: false,
	},
];

export const INSTALATIONMENU = [
	{
		keyApp: "instalations",
		navigation: "instalations/instalation",
		title: "Instalaciones",
		subtitle: "Tus instalaciones",
		image: `url(${instalation})`,
		showInMenu: false,
	},
	{
		keyApp: "equipments",
		navigation: "instalations/equipment",
		title: "Maquinarias",
		subtitle: "Tus equipos",
		image: `url(${equipment})`,
		showInMenu: false,
	},
];

export const INCIDENCESMENU = [
	{
		keyApp: "incidences",
		navigation: "incidences/incidences",
		title: "Incidencias",
		subtitle: "Tus incidencias",
		image: `url(${incidents})`,
		showInMenu: false,
		parent: "incidences",
	},
];

export const INVOICESMENU = [
	{
		keyApp: "invoices",
		navigation: "allInvoicesDeliveryNotes/invoices",
		title: "Facturas",
		subtitle: "Tus facturas",
		image: `url(${invoice})`,
		showInMenu: false,
	},
	{
		keyApp: "deliveryNotes",
		navigation: "allInvoicesDeliveryNotes/deliveryNotes",
		title: "Albaranes",
		subtitle: "Tus albaranes",
		image: `url(${deliveryNote})`,
		showInMenu: false,
		parent: "invoicesDeliveryNotes",
	},
];

export const SINGUPS = [
	{
		keyApp: "singUps",
		navigation: "singUps/singUpsList",
		title: "Control de accesos",
		subtitle: "Ver todos los accesos",
		image: `url(${business})`,
		showInMenu: true,
		parent: "singUpsMenu",
	},
	{
		keyApp: "timeRequests",
		navigation: "singUps/timeRequest",
		title: "Peticiones",
		subtitle: "Peticiones de cambio de fecha",
		image: `url(${deliveryNote})`,
		showInMenu: true,
		parent: "singUpsMenu",
	},
	{
		keyApp: "absences",
		navigation: "singUps/absences",
		title: "Ausencias",
		subtitle: "Ausencias",
		image: `url(${deliveryNote})`,
		showInMenu: true,
		parent: "singUpsMenu",
	},
	{
		keyApp: "map",
		navigation: "singUps/map",
		title: "Mapa",
		subtitle: "Mapa con los fichajes",
		image: `url(${map})`,
		showInMenu: true,
		parent: "singUpsMenu",
	},
];

export const RRHH = [
	{
		keyApp: "mySingUp",
		navigation: "RRHH/mySingUp",
		title: "Mis accesos",
		subtitle: "Consultar mis salidas y entradas",
		image: `url(${calendario})`,
		showInMenu: true,
		parent: "RRHH",
	},
	{
		keyApp: "holidayRequests",
		navigation: "RRHH/holidayRequest",
		title: "Vacaciones",
		subtitle: "Peticiones de vacaciones y bajas",
		image: `url(${vacaciones})`,
		showInMenu: true,
		parent: "RRHH",
	},
	// {
	//   keyApp: "workShifts",
	//   navigation: "RRHH/workShift",
	//   title: "Turnos",
	//   subtitle: "Turnos de trabajo",
	//   image: `url(${cambio})`,
	//   showInMenu: true,
	//   parent: "RRHH"
	// },
	{
		keyApp: "leaves",
		navigation: "RRHH/leave",
		title: "Bajas",
		subtitle: "Bajas de trabajo",
		image: `url(${clock})`,
		showInMenu: true,
		parent: "RRHH",
	},
	{
		keyApp: "overtimes",
		navigation: "RRHH/overtimes",
		title: "Horas extra",
		subtitle: "Horas extra",
		image: `url(${clock})`,
		showInMenu: true,
		parent: "RRHH",
	},
];

export const MASTER = [
	{
		keyApp: "product",
		navigation: "master/products",
		title: "Productos",
		subtitle: "Consultar productos",
		image: `url(${calendario})`,
		showInMenu: true,
		parent: "master",
	},
	{
		keyApp: "producttypes",
		navigation: "master/producttypes",
		title: "Tipo de producto",
		subtitle: "Ver todos los tipos de productos",
		image: `url(${reasons})`,
		showInMenu: true,
		parent: "master",
	},
	{
		keyApp: "tool",
		navigation: "master/tools",
		title: "Maquinarias",
		subtitle: "Maquinarias",
		image: `url(${calendario})`,
		showInMenu: true,
		parent: "master",
	},
	{
		keyApp: "tooltypes",
		navigation: "master/tooltypes",
		title: "Tipo de maquinaria",
		subtitle: "Ver todos los tipos de maquinaria",
		image: `url(${reasons})`,
		showInMenu: true,
		parent: "master",
	},
	{
		keyApp: "task",
		navigation: "master/tasks",
		title: "Tareas",
		subtitle: "Tareas",
		image: `url(${calendario})`,
		showInMenu: true,
		parent: "master",
	},
	{
		keyApp: "tasktypes",
		navigation: "master/tasktypes",
		title: "Tipo de tarea",
		subtitle: "Ver todos los tipos de tareas",
		image: `url(${reasons})`,
		showInMenu: true,
		parent: "master",
	},
	{
		keyApp: "commercialbrands",
		navigation: "master/commercialbrands",
		title: "Marca comercial",
		subtitle: "Ver todas las marcas comerciales",
		image: `url(${reasons})`,
		showInMenu: true,
		parent: "master",
	},
	{
		keyApp: "seed",
		navigation: "master/seeds",
		title: "Semillas",
		subtitle: "Semillas",
		image: `url(${calendario})`,
		showInMenu: false,
		parent: "master",
	},
	{
		keyApp: "adviser",
		navigation: "master/advisers",
		title: "Asesores",
		subtitle: "Asesores",
		image: `url(${calendario})`,
		showInMenu: false,
		parent: "master",
	},
	{
		keyApp: "crop",
		navigation: "master/crops",
		title: "Cultivos",
		subtitle: "Cultivos",
		image: `url(${calendario})`,
		showInMenu: true,
		parent: "master",
	},
	{
		keyApp: "variety",
		navigation: "master/varietys",
		title: "Variedades",
		subtitle: "Variedades",
		image: `url(${calendario})`,
		showInMenu: true,
		parent: "master",
	},
	{
		keyApp: "family",
		navigation: "master/familys",
		title: "Familias",
		subtitle: "Familias",
		image: `url(${calendario})`,
		showInMenu: true,
		parent: "master",
	},
	{
		keyApp: "providers",
		navigation: "master/providers",
		title: "Proveedores",
		subtitle: "Ver todos los proveedores",
		image: `url(${reasons})`,
		showInMenu: true,
		parent: "master",
	},
	{
		keyApp: "replacements",
		navigation: "master/replacements",
		title: "Recambios",
		subtitle: "Ver todos los recambios",
		image: `url(${reasons})`,
		showInMenu: true,
		parent: "master",
	},
	{
		keyApp: "refuelings",
		navigation: "master/refuelings",
		title: "Repostajes",
		subtitle: "Ver todos los repostajes",
		image: `url(${reasons})`,
		showInMenu: true,
		parent: "master",
	},
	{
		keyApp: "plagues",
		navigation: "master/plagues",
		title: "Plagas",
		subtitle: "Ver todos los tipos de plaga",
		image: `url(${reasons})`,
		showInMenu: true,
		parent: "master",
	},
	{
		keyApp: "jobtypes",
		navigation: "master/jobtypes",
		title: "Puesto de trabajo",
		image: `url(${reasons})`,
		showInMenu: true,
		parent: "master",
	},
];

export const HISTORIC = [
	{
		keyApp: "exploitations",
		navigation: "historics/exploitations",
		title: "Explotaciones",
		subtitle: "Consultar productos",
		image: `url(${calendario})`,
		showInMenu: true,
		parent: "historics",
	},
	{
		keyApp: "plots",
		navigation: "historics/plots",
		title: "Parcelas",
		subtitle: "Consultar productos",
		image: `url(${calendario})`,
		showInMenu: true,
		parent: "historics",
	},
	{
		keyApp: "enclosures",
		navigation: "historics/enclosures",
		title: "Segmentos",
		subtitle: "Consultar segmentos",
		image: `url(${calendario})`,
		showInMenu: true,
		parent: "historics",
	},
];

export const WORKS = [
	{
		keyApp: "work",
		navigation: "works/works",
		title: "Trabajos",
		subtitle: "Trabajo",
		image: `url(${configuration})`,
		hasChildren: false,
		showInMenu: true,
		parent: "workMenu",
	},
	{
		keyApp: "report",
		navigation: "works/reports",
		title: "Informes",
		subtitle: "Informes",
		image: `url(${configuration})`,
		hasChildren: false,
		showInMenu: true,
		parent: "workMenu",
	},
	{
		keyApp: "epis",
		navigation: "works/epis",
		title: "Epis",
		subtitle: "Epis",
		image: `url(${configuration})`,
		hasChildren: false,
		showInMenu: true,
		parent: "workMenu",
	},
];

export const BASICMENU = {
	configuration: CONFIGURATIONMENU,
	shopCarts: SHOPMENU,
	instalationsMenu: INSTALATIONMENU,
	projectsMenu: PROJECTSMENU,
	singUpsMenu: SINGUPS,
	RRHH: RRHH,
	master: MASTER,
	invoicesDeliveryNotes: INVOICESMENU,
	incidencesMenu: INCIDENCESMENU,
	workMenu: WORKS,
	historics: HISTORIC,
};

export const ALLMENU = Array.prototype.concat(
	PRINCIPALMENU,
	CONFIGURATIONMENU,
	SHOPMENU,
	INSTALATIONMENU,
	PROJECTSMENU,
	SINGUPS,
	RRHH,
	INVOICESMENU,
	INCIDENCESMENU,
	MASTER,
	HISTORIC,
	WORKS
);
