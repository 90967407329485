// https://testing-core.deploy.kuarasoftware.es/

export const API = {
	overtimes: {
		create: `/overtime`,
		edit: `/overtime/`,
		delete: `/overtime/`,
		list: `/overtime`,
	},
	jobtypes: {
		create: `/jobtype`,
		edit: `/jobtype/`,
		delete: `/jobtype/`,
		list: `/jobtype`,
	},
	auth: {
		login: `/user/login`,
		check2FA: `/user/check2FA`,
	},
	absences: {
		create: `/absence`,
		edit: `/absence/`,
		delete: `/absence/`,
		list: `/absence`,
	},
	users: {
		profile: `/user/profile`,
		create: `/user/register/`,
		edit: `/user/`,
		recover: `/recover/`,
		delete: `/user/`,
		list: `/user`,
		navUsers: `/navUsers`,
		products: `/products`,
	},
	hours: {
		create: `/hour`,
		edit: `/hour/`,
		delete: `/hour/`,
		list: `/hour`,
	},
	maintenances: {
		create: `/maintenance`,
		edit: `/maintenance/`,
		delete: `/maintenance/`,
		list: `/maintenance`,
	},
	equipmentConfigurations: {
		create: `/equipmentConfiguration`,
		edit: `/equipmentConfiguration/`,
		delete: `/equipmentConfiguration/`,
		list: `/equipmentConfiguration`,
	},
	instalations: {
		create: `/instalation`,
		edit: `/instalation/`,
		delete: `/instalation/`,
		list: `/instalation`,
		all: `/instalationAll`,
	},
	securitySheets: {
		create: `/securitySheet`,
		edit: `/securitySheet/`,
		delete: `/securitySheet/`,
		list: `/securitySheet`,
	},
	shopCartLines: {
		create: `/shopCartLine`,
		edit: `/shopCartLine/`,
		delete: `/shopCartLine/`,
		list: `/shopCartLine`,
	},
	satisfactions: {
		create: `/satisfaction`,
		edit: `/satisfaction/`,
		delete: `/satisfaction/`,
		list: `/satisfaction`,
	},
	incidences: {
		create: `/incidence`,
		edit: `/incidence/`,
		delete: `/incidence/`,
		list: `/incidence`,
	},
	equipments: {
		create: `/equipment`,
		edit: `/equipment/`,
		delete: `/equipment/`,
		list: `/equipment`,
		all: `/equipmentAll`,
	},
	singUps: {
		create: `/singUp`,
		edit: `/singUp/`,
		delete: `/singUp/`,
		list: `/singUp`,
	},
	projects: {
		create: `/project`,
		edit: `/project/`,
		delete: `/project/`,
		list: `/project`,
	},
	suggestions: {
		create: `/suggestion`,
		edit: `/suggestion/`,
		delete: `/suggestion/`,
		list: `/suggestion`,
	},
	workOrders: {
		create: `/workOrder`,
		edit: `/workOrder/`,
		delete: `/workOrder/`,
		list: `/workOrder`,
	},
	epis: {
		create: `/epi`,
		edit: `/epi/`,
		delete: `/epi/`,
		list: `/epi`,
	},
	bottomfertilizers: {
		create: `/bottomfertilizer`,
		edit: `/bottomfertilizer/`,
		delete: `/bottomfertilizer/`,
		list: `/bottomfertilizer`,
	},
	phytosanitarys: {
		create: `/phytosanitary`,
		edit: `/phytosanitary/`,
		delete: `/phytosanitary/`,
		list: `/phytosanitary`,
	},
	tags: {
		create: `/tag`,
		edit: `/tag/`,
		delete: `/tag/`,
		list: `/tag`,
	},
	dosifications: {
		create: `/dosification`,
		edit: `/dosification/`,
		delete: `/dosification/`,
		list: `/dosification`,
	},
	reasons: {
		create: `/reason`,
		edit: `/reason/`,
		delete: `/reason/`,
		list: `/reason`,
	},
	refuelings: {
		create: `/refueling`,
		edit: `/refueling/`,
		delete: `/refueling/`,
		list: `/refueling`,
	},
	fertigations: {
		create: `/fertigation`,
		edit: `/fertigation/`,
		delete: `/fertigation/`,
		list: `/fertigation`,
	},
	tooltypes: {
		create: `/tooltype`,
		edit: `/tooltype/`,
		delete: `/tooltype/`,
		list: `/tooltype`,
	},
	plagues: {
		create: `/plague`,
		edit: `/plague/`,
		delete: `/plague/`,
		list: `/plague`,
	},
	commercialbrands: {
		create: `/commercialbrand`,
		edit: `/commercialbrand/`,
		delete: `/commercialbrand/`,
		list: `/commercialbrand`,
	},
	tasktypes: {
		create: `/tasktype`,
		edit: `/tasktype/`,
		delete: `/tasktype/`,
		list: `/tasktype`,
	},
	providers: {
		create: `/provider`,
		edit: `/provider/`,
		delete: `/provider/`,
		list: `/provider`,
	},
	replacements: {
		create: `/replacement`,
		edit: `/replacement/`,
		delete: `/replacement/`,
		list: `/replacement`,
	},
	producttypes: {
		create: `/producttype`,
		edit: `/producttype/`,
		delete: `/producttype/`,
		list: `/producttype`,
	},
	familys: {
		create: `/family`,
		edit: `/family/`,
		delete: `/family/`,
		list: `/family`,
	},
	varietys: {
		create: `/variety`,
		edit: `/variety/`,
		delete: `/variety/`,
		list: `/variety`,
	},
	crops: {
		create: `/crop`,
		edit: `/crop/`,
		delete: `/crop/`,
		list: `/crop`,
	},
	works: {
		create: `/work`,
		edit: `/work/`,
		delete: `/work/`,
		list: `/work`,
	},
	tasks: {
		create: `/task`,
		edit: `/task/`,
		delete: `/task/`,
		list: `/task`,
	},
	advisers: {
		create: `/adviser`,
		edit: `/adviser/`,
		delete: `/adviser/`,
		list: `/adviser`,
	},
	seeds: {
		create: `/seed`,
		edit: `/seed/`,
		delete: `/seed/`,
		list: `/seed`,
	},
	tools: {
		create: `/tool`,
		edit: `/tool/`,
		delete: `/tool/`,
		list: `/tool`,
	},
	products: {
		create: `/product`,
		edit: `/product/`,
		delete: `/product/`,
		list: `/product`,
	},
	exploitations: {
		create: `/exploitation`,
		edit: `/exploitation/`,
		delete: `/exploitation/`,
		list: `/exploitation`,
		location: `/location`,
	},
	plots: {
		create: `/plot`,
		edit: `/plot/`,
		delete: `/plot/`,
		list: `/plot`,
	},
	enclosures: {
		create: `/enclosure`,
		edit: `/enclosure/`,
		delete: `/enclosure/`,
		list: `/enclosure`,
	},
	roles: {
		create: `/role`,
		edit: `/role/`,
		delete: `/role/`,
		list: `/role`,
	},
	workShift: {
		create: `/workShift`,
		edit: `/workShift/`,
		delete: `/workShift/`,
		list: `/workShift`,
	},
	leave: {
		create: `/leave`,
		edit: `/leave/`,
		delete: `/leave/`,
		list: `/leave`,
	},
	dosificationProduct: {
		create: `/dosificationProduct`,
		edit: `/dosificationProduct/`,
		delete: `/dosificationProduct/`,
		list: `/dosificationProduct`,
	},
	dosificatorConfig: {
		create: `/dosificatorConfig`,
		edit: `/dosificatorConfig/`,
		delete: `/dosificatorConfig/`,
		list: `/dosificatorConfig`,
	},
	OPCUA: {
		create: `/OPCUA`,
		edit: `/OPCUA/`,
		delete: `/OPCUA/`,
		list: `/OPCUA`,
		browse: `/OPCUA/browse`,
	},
	expenses: {
		create: `/expense`,
		edit: `/expense/`,
		delete: `/expense/`,
		list: `/expense`,
	},
	establishmentInfo: {
		create: `/establishmentInfo`,
		edit: `/establishmentInfo/`,
		delete: `/establishmentInfo/`,
		list: `/establishmentInfo`,
	},
	imputHours: {
		create: `/imputHour`,
		edit: `/imputHour/`,
		delete: `/imputHour/`,
		list: `/imputHour`,
	},
	timeRequests: {
		create: `/timeRequest`,
		edit: `/timeRequest/`,
		delete: `/timeRequest/`,
		list: `/timeRequest`,
	},
	holidayRequests: {
		create: `/holidayRequest`,
		edit: `/holidayRequest/`,
		delete: `/holidayRequest/`,
		list: `/holidayRequest`,
	},
	alerts: {
		create: `/alert`,
		edit: `/alert/`,
		delete: `/alert/`,
		list: `/alert`,
	},
	downloadDocuments: {
		list: `/downloadDocument`,
	},
};
