import { axios } from "../connection/ConnectionHandler";
import { fetchJobtypes } from "./jobtypesActions";
import { APP_COOKIE_NAME, AUTH, PASS_COOKIE_NAME, SET_CURRENT_ROUTE, SET_PROFILE, USER_COOKIE_NAME, USER_ID_COOKIE_NAME } from "../constants";
import { API } from "../constants/api";
import { deleteCookie, getCookie, setCookie } from "../utils";
import { fetchUsers } from "./usersActions";
import { routes } from "../constants/routes";
import { fetchSingUps } from "./singUpActions";
import { fetchIncidences } from "./incidencesActions";
import { fetchAlerts } from "./alertsActions";
import { fetchSecuritySheets } from "./securitySheetsActions";
import { fetchTimeRequests } from "./timeRequestActions";
import { fetchHolidayRequests } from "./holidayRequestActions";
import { fetchWorkShift } from "./workShiftsActions";
import { fetchLeave } from "./leavesActions";
import { fetchToolTypes } from "./toolTypesActions";
import { fetchCommercialBrands } from "./commercialBrandsActions";
import { fetchTasktypes } from "./taskTypesActions";
import { fetchProviders } from "./providersActions";
import { fetchReplacements } from "./replacementsActions";
import { fetchWorkOrders } from "./workOrdersActions";
import { fetchBottomFertilizers } from "./bottomFertilizerActions";
import { fetchRoles } from "./rolesActions";
import { fetchExploitations } from "./exploitationsActions";
import { fetchPlots } from "./plotsActions";
import { fetchProducts } from "./productsActions";
import { fetchTools } from "./toolsActions";
import { fetchSeeds } from "./seedsActions";
import { fetchMaintenances } from "./maintenancesActions";
import { fetchAdvisers } from "./advisersActions";
import { fetchTasks } from "./tasksActions";
import { fetchWorks } from "./worksActions";
import { fetchHours } from "./hoursActions";
import { fetchCrops } from "./cropsActions";
import { fetchVarietys } from "./varietysActions";
import { fetchEnclosures } from "./enclosuresActions";
import { fetchFamilys } from "./familysActions";
import { fetchProductTypes } from "./productTypesActions";
import { fetchFertigations } from "./fertigationsActions";
import { fetchRefuelings } from "./refuelingsActions";
import { fetchPlagues } from "./plaguesActions";
import { fetchPhytosanitarys } from "./phytosanitaryActions";
import { fetchEpis } from "./episActions";
import { fetchEstablishmentInfo } from "./establishmentInfoActions";
import { fetchSuggestions } from "./suggestionsActions";
import { fetchAbsences } from "./absencesActions";
import { fetchOvertimes } from "./overtimesActions";

const rememberLogin = (username, password, remember) => {
	if (remember) {
		setCookie(USER_COOKIE_NAME, username, 30);
		setCookie(PASS_COOKIE_NAME, password, 30);
	} else {
		deleteCookie(USER_COOKIE_NAME, "");
		deleteCookie(PASS_COOKIE_NAME, "");
	}
};

/* PERSISTENCY CHECK */
export const checkToken = () => {
	return async (dispatch) => {
		const token = await getCookie(APP_COOKIE_NAME);
		const userId = await getCookie(USER_ID_COOKIE_NAME);
		if (token) {
			dispatch({ type: AUTH, payload: { auth: true, token, userId } });
		}
	};
};

export const fetchUserProfile = () => {
	return async (dispatch) => {
		const response = await axios
			.get(API.users.profile)
			.then((response) => {
				dispatch({ type: SET_PROFILE, payload: response.data });
				return response;
			})
			.catch((err) => err);
		return response;
	};
};

export const uploadImage = (data, url) => {
	return async (dispatch) => {
		const response = await axios
			.post(url, data)
			.then((response) => {
				return response;
			})
			.catch((err) => err);
		return response;
	};
};

export const deleteImage = (url) => {
	return async (dispatch) => {
		const response = await axios
			.delete(url)
			.then((response) => {
				return response;
			})
			.catch((err) => err);
		return response;
	};
};

/* INITAL DATA LOAD */
export const loadInitialData = () => {
	return async (dispatch) => {
		dispatch(fetchRoles());
		dispatch(fetchJobtypes());
		dispatch(fetchUsers());
		dispatch(fetchSingUps());
		dispatch(fetchIncidences());
		dispatch(fetchAlerts());
		dispatch(fetchSecuritySheets());
		dispatch(fetchHolidayRequests());
		dispatch(fetchTimeRequests());
		dispatch(fetchWorkShift());
		dispatch(fetchLeave());
		dispatch(fetchToolTypes());
		dispatch(fetchPlagues());
		dispatch(fetchCommercialBrands());
		dispatch(fetchTasktypes());
		dispatch(fetchProviders());
		dispatch(fetchReplacements());
		dispatch(fetchWorkOrders());
		dispatch(fetchBottomFertilizers());
		dispatch(fetchPhytosanitarys());
		dispatch(fetchExploitations());
		dispatch(fetchPlots());
		dispatch(fetchProducts());
		dispatch(fetchTools());
		dispatch(fetchSeeds());
		dispatch(fetchMaintenances());
		dispatch(fetchAdvisers());
		dispatch(fetchTasks());
		dispatch(fetchWorks());
		dispatch(fetchHours());
		dispatch(fetchCrops());
		dispatch(fetchVarietys());
		dispatch(fetchEnclosures());
		dispatch(fetchFamilys());
		dispatch(fetchProductTypes());
		dispatch(fetchFertigations());
		dispatch(fetchRefuelings());
		dispatch(fetchEpis());
		dispatch(fetchEstablishmentInfo());
		dispatch(fetchSuggestions());
		dispatch(fetchAbsences());
		dispatch(fetchOvertimes());
	};
};

export const login = (username, password, remember) => {
	return async (dispatch) => {
		const response = await axios
			.post(API.auth.login, { username, password })
			.then((response) => {
				if (response && response.data) {
					const { access_token } = response.data || {};
					rememberLogin(username, password, remember);
					dispatch({
						type: AUTH,
						payload: { auth: true, token: access_token },
					});
					dispatch({ type: SET_PROFILE, payload: response.data.resUser });
					dispatch({
						type: SET_CURRENT_ROUTE,
						payload: routes["home"],
					});
				}
				return response;
			})
			.catch((err) => err);
		return response;
	};
};

export const check2FA = (data) => {
	return async (dispatch) => {
		const response = await axios
			.post(API.auth.check2FA, data)
			.then((response) => {
				if (response && response.data) {
					const { access_token } = response.data || {};
					rememberLogin(data.username, data.password, data.remember);
					dispatch({
						type: AUTH,
						payload: { auth: true, token: access_token },
					});
					dispatch({ type: SET_PROFILE, payload: response.data.resUser });
					dispatch({
						type: SET_CURRENT_ROUTE,
						payload: routes["home"],
					});
				}
				return response;
			})
			.catch((err) => err);
		return response;
	};
};

export const uploadDocument = (data, url) => {
	return async (dispatch) => {
		const response = await axios
			.post(url, data)
			.then((response) => {
				return response;
			})
			.catch((err) => err);
		return response;
	};
};

export const deleteDocument = (url) => {
	return async (dispatch) => {
		const response = await axios
			.delete(url)
			.then((response) => {
				return response;
			})
			.catch((err) => err);
		return response;
	};
};
